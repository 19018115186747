import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import { TranslatedBlock } from 'src/components/language';
import { TranslatableText } from '../../components/language';
import Disclaimer from '../../components/disclaimer';
import LearnMore from '../../components/learn-more';
import OurVisionCareBlackEN from '../../images/OurVisionCare_Final_RGB_EN.svg';
import OurVisionCareBlackFR from '../../images/OurVisionCare_Final_RGB_FR.svg';
import HowCan1 from '../../images/how-can-1.svg';
import HowCan2 from '../../images/how-can-2.svg';
import HowCan3 from '../../images/how-can-3.svg';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('ourvisioncare-program');
  }

  render() {
    let title = {
      english: 'Our VISION Care Patient Assistance Program',
      french: 'Programme d’assistance aux patients Nos soins de la VUE',
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <TranslatedBlock language="english">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1>
                    <img loading="lazy" src={OurVisionCareBlackEN} alt="Our Vision Care" className={'img-fluid'} />
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-5">
                  <h2 className="h1 mb-4">What is a Patient Support Program?</h2>
                  <p>
                    Most Patient Support Programs are designed to help people get the medicine that their doctor has
                    prescribed. This can mean helping them understand whether they have coverage for medicines through
                    their insurance or other public programs or helping them find other financial support.
                  </p>
                </div>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1>
                    <img loading="lazy" src={OurVisionCareBlackFR} alt="Our Vision Care" className={'img-fluid'} />
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-5">
                  <h2 className="h1 mb-4">Qu’est-ce qu’un programme d’assistance aux patients?</h2>
                  <p>
                    La plupart des programmes d’assistance aux patients sont conçus pour aider les gens à obtenir un
                    médicament prescrit par leur médecin. Les responsables de ces programmes peuvent notamment aider les
                    patients à déterminer si leur assureur ou des programmes publics remboursent leurs médicaments, ou
                    encore les orienter vers d’autres sources de soutien financier.
                  </p>
                </div>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <div className="container mb-5">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <TranslatedBlock language="english">
                <h2 className="head-text">How can the Our VISION Care Program help me?</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="head-text">
                  Quels sont les services offerts dans le cadre du programme Nos soins de la VUE?
                </h2>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center sans-font">
              <img loading="lazy" src={HowCan1} alt="Umbrella Icon" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english:
                      'Helps you find out if you have coverage for VABYSMO and helps with all the paperwork that is needed',
                    french:
                      'Aide pour déterminer si vous êtes admissible au remboursement de VABYSMO et pour remplir tous les documents requis.',
                  }}
                />
              </p>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center">
              <img loading="lazy" src={HowCan2} alt="Question Mark Icon" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english: 'Provides answers to questions you may have about VABYSMO',
                    french: 'Réponses à vos questions sur VABYSMO.',
                  }}
                />
              </p>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center sans-font">
              <img loading="lazy" src={HowCan3} alt="English to French translation Icon" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english:
                      'Our VISION Care Program staff can use translation services to speak to you in the language that you prefer',
                    french:
                      'Accès à des services de traduction par le personnel du programme Nos soins de la VUE pour vous parler dans la langue de votre choix.',
                  }}
                />
              </p>
            </div>
          </div>
        </div>

        <LearnMore />
        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
